<template>
  <v-app>
    <template id="app" v-if="this.$store.state.logged == false">
      <default-login />
    </template>
    <template v-else>
      <div>
        <v-card class="externalLinks" v-if="!!pullDown">
          <v-tabs centered>
            <v-tab @click="triggerPullDown()">NIKITA</v-tab>
            <v-tab disabled href="" target="_blank">SPARROW</v-tab>
            <v-tab href="https://docs.myfamily.network" target="_blank">DOCS</v-tab>
            <v-tab href="https://b2b.myfamily.it/it/" target="_blank">B2B</v-tab>
            <v-tab href="https://partner.myfamily.network/" target="_blank">PARTNERHUB</v-tab>
          </v-tabs>
        </v-card>
        <div :class="{ 'pull-down': pullDown, 'pull-up': !pullDown }">
          <default-drawer />
          <default-bar :id="tenantCollectionDropdown.length" />
          <default-view />
          <default-footer />
          <v-snackbar :timeout="-1" v-model="showEnvironment" bottom left color="pink darken-3" elevation="26" style="margin-left: 260px;">
            {{ environmentMessage }}
          </v-snackbar>
          <v-dialog v-model="calendarDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="triggerCalendarDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text>{{ environmentMessage }}</v-btn>
              </v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn @click="triggerCalendarDialog" v-t="'close'"></v-btn>
            </v-toolbar>
            <v-card class="h-80">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="6">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-card class="h-40" tile elevation="5">
                          <v-card-text>
                            <v-sheet>
                              <MFNotificationsInner></MFNotificationsInner>
                            </v-sheet>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-card class="h-60" tile elevation="5">
                          <v-card-text>
                            <v-sheet>
                              <v-card>
                                <v-card-title>
                                  <v-text-field
                                      v-model="searchPotential"
                                      append-icon="mdi-magnify"
                                      :label="$t('search')"
                                      single-line
                                      hide-details
                                  ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    dense
                                    :headers="headersPotential"
                                    :items="potentialCustomers"
                                    :search="searchPotential"
                                    :loading="!!isLoading"
                                    :loading-text="$t('loading')"
                                >
                                  <template v-slot:item.businessName="{ item }">
                                    <div @click="redirectTo(item)" class="pointer">
                                      {{ item.businessName }}
                                    </div>
                                  </template>
                                  <template v-slot:item.country="{ item }">
                                    <div class="pointer">
                                      {{ item.country }}
                                    </div>
                                  </template>
                                  <template v-slot:item.updateTimestamp="{ item }">
                                    {{ item.updateTimestamp | formatDate }}
                                  </template>
                                  <template v-slot:item.action="{ item }">
                                    <v-icon small @click="redirectTo(item)">mdi-open-in-new</v-icon>
                                  </template>
                                  <template v-slot:no-data>
                                    <v-btn dense icon raised elevation="2" color="secondary" class="my-3 py-3">
                                      <v-icon small @click="initialize()">mdi-refresh</v-icon>
                                    </v-btn>
                                  </template>
                                </v-data-table>
                              </v-card>
                            </v-sheet>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="6">
                    <v-card class="h-90" tile elevation="5">
                      <v-card-text>
                        <v-sheet>
                          <MFCalendar></MFCalendar>
                        </v-sheet>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <div class="mini-version" @click="showReleaseNotes">
            {{ environmentMessageShort }}
          </div>
        </div>
      </div>
    </template>
  </v-app>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import MFNotificationsInner from '../../components/utility/MFNotificationsInner.vue'
import MFCalendar from '../../components/utility/MFCalendar.vue'
export default {
  name: "DefaultLayout",
  components: {
    MFNotificationsInner,
    MFCalendar
  },
  created: function () {
    this.initialize();
  },
  computed: {
    calendarDialog: sync("utils/calendarDialod"),
    pullDown: sync("utils/pullDown"),
    tenantCollectionDropdown: get('tenantConfiguration/tenantCollectionDropdown'),
    potentialCustomers: get('customers/potentialCustomers'),
    isLoading: sync("utils/isLoading"),
    showRelease: sync("notifications/showRelease"),
    headersPotential(){
      return [
        { text: this.$t('businessName'), align: 'start', value: 'businessName' },
        { text: 'Country', value: 'country' },
        { text: this.$t('date'), value: 'updateTimestamp' },
        { text: this.$t('actions'), value: 'action' }
      ]
    }
  },
  data: () => ({
    environmentMessage: '',
    environmentMessageShort: '',
    showEnvironment: false,
    notifications: false,
    sound: true,
    widgets: false,
    searchPotential: ''
  }),
  methods: {
    initialize: function () {
      this.initCustomerPotentialCollection();
    },
    redirectTo(item) {
      this.$window.location.assign(`/customers/${item.id}`)
      this.triggerCalendarDialog()
    },
    ...call("utils", ["triggerPullDown", "triggerCalendarDialog"]),
    ...call("customers/*"),
    ...call("notifications/*")
  },
  mounted() {
    this.environmentMessage = `Environment: ${process.env.VUE_APP_ENVIRONMENT.toUpperCase()} (${process.env.VUE_APP_VERSION})`
    this.environmentMessageShort = `${process.env.VUE_APP_ENVIRONMENT.toUpperCase()} (${process.env.VUE_APP_VERSION})`
    this._keyListener = function (e) {
      switch (e.key) {
        case 'Escape':
          e.preventDefault()
          this.triggerPullDown()
          break
        case '}':
          e.preventDefault()
          this.$window.location.assign(`/tnt`)
          break
        case '|':
          e.preventDefault()
          this.triggerCalendarDialog()
          break
        case 'F1':
          e.preventDefault()
          localStorage.setItem('lang', 'en-US')
          window.location.reload()
          break
        case 'F2':
          e.preventDefault()
          localStorage.setItem('lang', 'pt-BR')
          window.location.reload()
          break
        case 'F3':
          e.preventDefault()
          localStorage.setItem('lang', 'it-IT')
          window.location.reload()
          break
        case 'F4':
          e.preventDefault()
          localStorage.setItem('lang', 'jp-JP')
          window.location.reload()
          break
      }
    }
    document.addEventListener('keydown', this._keyListener.bind(this))
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this._keyListener)
  },
  components: {
    DefaultLogin: () => import(/* webpackChunkName: "default-login" */ "./Login"),
    DefaultBar: () => import(/* webpackChunkName: "default-app-bar" */ "./AppBar"),
    DefaultDrawer: () => import(/* webpackChunkName: "default-drawer" */ "./Drawer"),
    DefaultFooter: () => import(/* webpackChunkName: "default-footer" */ "./Footer"),
    DefaultView: () => import(/* webpackChunkName: "default-view" */ "./View")
  }
}
</script>

<style>
.mini-version {
  position: fixed;
  bottom: 0px;
  right: 0px;
  padding: 2px 10px;
  color: white;
  background: #606060;
  border-radius: 8px 0 0 0;
  font: 400;
  font-size: medium;
  cursor: pointer;
}
.fade { -webkit-mask-image: linear-gradient(90deg, #000 60%, transparent); }
.h-100 { max-height: 100vh; overflow-y: scroll; }
.h-90 { max-height: 90vh; overflow-y: scroll; }
.h-80 { max-height: 80vh; overflow-y: scroll; }
.h-70 { max-height: 70vh; overflow-y: scroll; }
.h-60 { max-height: 60vh; overflow-y: scroll; }
.h-50 { max-height: 50vh; overflow-y: scroll; }
.h-40 { max-height: 40vh; overflow-y: scroll; }
.h-30 { max-height: 30vh; overflow-y: scroll; }
.h-20 { max-height: 20vh; overflow-y: scroll; }
.h-10 { max-height: 10vh; overflow-y: scroll; }
</style>
